*, *::before, *::after {
  box-sizing: border-box;
}

#root, .calculator-grid {
  height: 100%;
}

html {
  min-height: 100%;
  height: 100%;
}

body {
  /* padding: 50px; */
  /* background: linear-gradient(to right, #00AAFF, #00FF6C); */
  background-color: rgba(0, 0, 0, 1);
  min-height: 100%;
  height: 100%;
}

.calculator-grid {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, 6rem);
  grid-template-rows: minmax(7rem, auto) repeat(5, 6rem);
}

.calculator-grid > button {
  cursor: pointer;
    color: white;
    font-size: 2rem;
    border: 1px solid black;
    outline: none;
    background-color: rgba(0, 0, 0, .80);
    border-radius: 100%;
}

.calculator-grid > button:hover,
.calculator-grid > button:focus {
  /* background-color: rgba(255, 255, 255, .9); */
}

.span-two {
  grid-column: span 2;
}

.magicbtn{
  background-color: transparent;
  height:100%;
  width:100px;
  position:absolute;
  left:0;
  border: none;
  top:0
}

.output {
  position: relative;
    grid-column: 1 / -1;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border: 2px solid black;
    justify-content: space-around;
    padding: 0.75rem;
    word-wrap: break-word;
    word-break: break-all;
    border-bottom-width: 1px;
    background-color: rgba(255, 255, 255, 0.1);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 100%;
}

.rounded {
  border-radius: 100%;
  background-color: rgba(255,255,255,0.2) !important;
  border-width: 12px !important;
}

.placeholderBtn {
  pointer-events: none;
}

.output .previous-operand {
  color: rgba(255, 255, 255, .75);
  font-size: 1.5rem;
}

.output .current-operand {
  color: white;
  font-size: 2.5rem;
}